<template>
    <div class="report-view-container">
        <div class="title-container">
            <!-- <el-button @click="closeView()"><i class="el-icon-back"></i>返回</el-button> -->
            <div class="back-icon" @click="closeView()"><i class="el-icon-back"></i>
                <span class="view-name">返回</span></div>
        </div>
        <div class="report-container">
            <img v-show="hasReport" class="report-img" :src="`${study.pacsFasUrl}${study.rptStorageId}/getobject_inner_file.do?objectuid=${study.rptPath}&filename=report_1.jpg`" alt="无报告" />
            <div class="hint-container">
                <div class="hint-box">
                    <Loading v-show="showLoading" />
                    <div v-show="showHint">{{loadingHint}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { store } from '../../utils/store'
import Loading from '../Loading.vue'

export default {
    name: 'ReportSnapshotView',
    components: {
        Loading
    },
    props: {
        study: {}
    },
    data() {
        return {
            imageUrl: store.imageUrl,
            hasReport: true,
            showLoading: true,
            showHint: true,
            loadingHint: "正在加载报告"
        };
    },
    mounted() {
        // if (window.history && window.history.pushState) {
        //     history.pushState({a:"report"}, null);
        //     console.log("mounted 报告单")
        //     window.addEventListener('popstate', this.goBack, false);
        // }

        document.querySelector(".report-img").onload = () => {
            this.showLoading = false;
            this.showHint = false;
        }
        document.querySelector(".report-img").onerror = () => {
            this.hasReport = false;
            this.showLoading = false;
            this.loadingHint = "显示报告失败";
        }
    },
    destroyed() {
        // console.log("destroyed 报告单")
        // window.removeEventListener('popstate', this.goBack, false);
    },
    methods: {
        closeView() {
            // this.$emit("closeView");
            // this.$router.go(-1);
            // history.back();

            this.$emit('update:reportViewVisible', false)
        },
        goBack(state) {
            console.log(state)
            console.log("回退 报告单")
            this.$message.info("回退 报告单");
            this.closeView();
        }
    }
}
</script>

<style scoped>
    .report-view-container {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: #f6f6f6;
        /* background-color: rgba(255,255,255,0.5); */
        overflow-y: auto;
        z-index: 4;
    }
    .title-container {
        height: 12vmin;
        display: flex;
        align-items: stretch;
    }
    .title-container .back-icon {
        font-size: 6vmin;
        padding: 0 3vmin;

        display: flex;
        align-items: center;
    }
    .title-container .view-name {
        font-size: 4vmin;
        margin-left: 3vmin;
    }
    .title-container .back-icon:active {
        background-color: #eaeaea;
    }

    .report-container {
        position: absolute;
        left: 0;
        right: 0;
        top: 12vmin;
        bottom: 0;
        background-color: #f6f6f6;
        font-size: 4vmin;
        text-align: left;
        overflow: auto;
    }
    .report-img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        margin: auto;
    }
    .hint-container {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 2;

        display: flex;
        justify-content: center;
        align-items: center;
    }
    .hint-container .hint-box {
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        align-items: center;
    }
</style>